import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, map, mergeMap, of, switchMap } from 'rxjs';
import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { environment } from '../../environments/environment';
import { BcUser, CompanyBasic } from '../models/auth/BcUser';
import { UserPartner } from '../models/api/UserPartner';
import { ResetPasswordModel } from '../models/auth/ResetPasswordModel';
import { UserContactPartner } from '../models/auth/UserContactPartner';
import { CompanyCountryViewModel } from '../models/api/CompanyCountry';
import { Router } from '@angular/router';
import { UserPartnersList } from '../models/api/UserPartnersList';
import { History } from '../models/api/History';
import { NgxPermissionsService } from 'ngx-permissions';
import { Role } from '@app/@shared/constants/role.constant';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private manager: UserManager;
  private user: User | null;
  private permissionStorageName: string = 'userPermissions';
  private userStorageName: string = 'currentUser';
  private partnerStorageName: string = 'currentPartner';
  public currentUserSubject: BehaviorSubject<BcUser>;
  public isLoggedIn: BehaviorSubject<Boolean>;

  public selectedPartner: BehaviorSubject<UserPartner>;
  public partners: BehaviorSubject<UserPartner[]>;
  public history: BehaviorSubject<History[]>;
  public partnersUpdated: Boolean = false;

  public isBcSuper: boolean = false;
  // public LicenseViewingMode: boolean = true;
  constructor(private http: HttpClient, private router: Router, private permissionsService: NgxPermissionsService) {
    this.manager = new UserManager(this.getClientSettings());
    this.currentUserSubject = new BehaviorSubject<BcUser>(null);
    this.isLoggedIn = new BehaviorSubject<Boolean>(false);
    this.selectedPartner = new BehaviorSubject<UserPartner>(null);
    this.partners = new BehaviorSubject<UserPartner[]>(null);
    this.initialSetUpUser();
  }

  public get currentUserValue(): BcUser {
    return this.currentUserSubject.value;
  }

  public get currentSelectedPartner(): UserPartner {
    return this.selectedPartner.value;
  }

  requestAccess(email: string, fullName: string, company: string, country: string) {
    return this.http.post(
      `${environment.apiUrl}/Authentication/RequestAccess`,
      { email, fullName, company, country },
      { responseType: 'text' }
    );
  }

  getCompaniesForEmail(email: string): Observable<any> {
    return this.http.get<Array<CompanyCountryViewModel>>(
      `${environment.apiUrl}/Authentication/email-company-list/${email}`
    );
  }

  logout() {
    this.manager
      .signoutRedirect()
      .then(() => {
        // remove user from local storage to log user out
        localStorage.removeItem(this.userStorageName);
        localStorage.removeItem(this.partnerStorageName);
        localStorage.removeItem(this.permissionStorageName);
        this.isLoggedIn.next(false);
        this.currentUserSubject.next(null);
        this.selectedPartner.next(null);
        window.location.href =
          environment.authenticationServiceUrl + '/Account/SignOff?returnUrl=' + environment.thisUrl;
      })
      .catch(() => {});
  }

  public setUpPartner(no: string): Observable<boolean> {
    var partner = this.partners.value.find((p) => p.no == no);
    if (partner == null) return of(false);
    localStorage.setItem(this.partnerStorageName, JSON.stringify(partner));
    this.selectedPartner.next(partner);

    return this.getUserRoles(partner.no);
  }

  public cleanUpSelectedPartner() {
    this.selectedPartner.next(null);
  }

  private setUpUserStorage(user: BcUser) {
    localStorage.setItem(this.userStorageName, JSON.stringify(user));
    this.currentUserSubject.next({ ...user });
  }

  private initialSetUpUser() {
    var userStorage = localStorage.getItem(this.userStorageName);
    var user = null;
    if (userStorage && userStorage != null && userStorage != 'undefined') user = JSON.parse(userStorage);

    if (user != null) {
      this.isLoggedIn.next(true);
      this.currentUserSubject.next({ ...user });

      var partner = null;
      var partnerJson = localStorage.getItem(this.partnerStorageName);
      if (partnerJson && partnerJson != null && partnerJson != 'undefined') partner = JSON.parse(partnerJson);

      if (partner != null) {
        this.selectedPartner.next(partner);
      }
    }
  }

  public getApiPartners(): Observable<UserPartnersList> {
    return this.http.get<UserPartnersList>(`${environment.apiUrl}/Partners/GetAllForLoggedUser`).pipe(
      switchMap((data) => {
        this.isBcSuper = data.isBcSuper;
        this.partners.next(data.partners);
        this.partnersUpdated = true;

        if (!this.isBcSuper && data.partners.length == 1) {
          return this.setUpPartner(data.partners[0].no).pipe(map(() => data));
        }
        return of(data);
      })
    );
  }

  checkLsAdmStatus(): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiUrl}/Authentication/check-lsadm`).pipe(
      map((isLsAdm) => {
        if (isLsAdm) {
          var user = this.currentUserSubject.value;
          user.isLsAdmin = isLsAdm;
          this.setUpUserStorage(user);
        }
        return isLsAdm;
      })
    );
  }

  public updatePermissions(roles: string[], partnerNo: string): Observable<boolean> {
    var user = this.currentUserSubject.value;
    user.roles = roles;
    this.permissionsService.flushPermissions();
    localStorage.removeItem(this.permissionStorageName);

    if (roles) {
      this.permissionsService.loadPermissions(roles);

      var customerPermission =
        (user.roles?.includes(Role.BCSUPER) ||
          user.roles?.includes(Role.BCADMIN) ||
          user.roles?.includes(Role.BCCUSTOME)) &&
        !this.currentSelectedPartner.isDirEnd;

      this.permissionsService.addPermission(Role.CUSTOMERVISIBLE, (permissionName, permissionsObject) => {
        return customerPermission;
      });

      if (customerPermission) {
        roles.push(Role.CUSTOMERVISIBLE);
      }

      localStorage.setItem(this.permissionStorageName, JSON.stringify(roles));
    }
    return of(true);
  }

  public getUserRoles(partnerNo: string): Observable<boolean> {
    return this.http.post<string[]>(`${environment.apiUrl}/Partners/GetRoles/`, { no: partnerNo }).pipe(
      switchMap((roles) => {
        return this.updatePermissions(roles, partnerNo).pipe(switchMap(() => this.updateCompanies(partnerNo)));
      })
    );
  }

  public updateCompanies(partnerNo: string): Observable<boolean> {
    var user = this.currentUserSubject.value;
    if (!(user.roles?.includes(Role.BCADMIN) || user.roles?.includes(Role.BCSUPER))) {
      return of(false);
    }

    return this.getAdminCompanies(partnerNo).pipe(
      map((companies) => {
        user.bcAdminCompanies = companies;
        this.setUpUserStorage(user);
        return true;
      })
    );
  }

  public getAdminCompanies(partnerNo: string): Observable<CompanyBasic[]> {
    return this.http.post<CompanyBasic[]>(`${environment.apiUrl}/Partners/GetBcAdminCompanies/`, { no: partnerNo });
  }

  restorePermissions() {
    var data = localStorage.getItem(this.permissionStorageName);
    if (data != null) {
      var permissions = JSON.parse(data);
      this.permissionsService.loadPermissions(permissions);
    }
  }

  public getPasswordChangeLinkRequest(email: string): Observable<string> {
    return this.http.get(`${environment.apiUrl}/Authentication/send-password-reset-link/` + email, {
      responseType: 'text',
    });
  }

  public getPasswordChangeRequest(params: ResetPasswordModel): Observable<string> {
    return this.http.post(`${environment.apiUrl}/Authentication/reset-password/`, params, { responseType: 'text' });
  }

  public getUsersMap(): Observable<UserContactPartner[]> {
    return this.http.get<UserContactPartner[]>(`${environment.apiUrl}/Authentication/contact-partner-list`);
  }

  async completeAuthentication() {
    return await this.manager
      .signinRedirectCallback()
      .then((user) => {
        if (!user.access_token) {
          //console.log('signinRedirectCallback-failed: ', user);
          return;
        }
        //console.log('signinRedirectCallback-success: ', user);
        this.user = user;
        var expirationDate = new Date();
        expirationDate.setSeconds(expirationDate.getSeconds() + this.user.expires_in);
        const bcUser: BcUser = {
          id: this.user.profile.sub,
          email: this.user.profile.email,
          fullname: this.user.profile['FullName'],
          token: this.user.access_token,
          tokenexpirationdate: expirationDate,
          company: this.user.profile['company'],
          country: this.user.profile['country'],
          isLsAdmin: false,
          bcAdminCompanies: [],
          roles: [],
        };
        this.setUpUserStorage(bcUser);
        this.isLoggedIn.next(true);

        this.getApiPartners().subscribe(() => {
          this.checkLsAdmStatus().subscribe(() => {
            this.router.navigate(['/home']);
          });
        });
      })
      .catch((err) => {
        //console.log('signinRedirectCallback-error: ', err);
        this.router.navigate(['/login']);
      });
  }

  public isAuthenticated = async (): Promise<boolean> => {
    return await this.manager.getUser().then((user) => {
      if (this.user !== user) {
        this.user = user;
      }
      var authenticated = this.checkUser(user);
      return authenticated;
    });
  };

  private checkUser = (user: User | null): boolean => !!user && !user.expired;

  async loginOauth() {
    return await this.manager.signinRedirect();
  }

  getClientSettings(): UserManagerSettings {
    return {
      authority: environment.authenticationServiceUrl,
      client_id: 'BusinessCenter',
      redirect_uri: environment.thisUrl + '/auth-callback',
      response_type: 'id_token token',
      scope: 'BusinessCenter openid profile',
      filterProtocolClaims: true,
      loadUserInfo: true,
      //automaticSilentRenew: true
    };
  }
}
