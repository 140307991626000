import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { BreadcrumbElement, ToolsService } from './services/tools.service';
import { environment } from '../environments/environment';
import { UserRolesService } from './services/user-roles.service';
import { UserPartner } from './models/api/UserPartner';
import { Subscription } from 'rxjs';
import { RoleConstants } from './@shared/constants/role.constant';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [RoleConstants],
})
export class AppComponent {
  subscription: Subscription;
  title = 'BusinessCenter';
  pageName: string;
  subBreadcrumbs: BreadcrumbElement[] = [];
  userName: string = '';
  userRoles: string[] = [];
  partnerName: string = '';
  isLsAdmin: boolean = false;
  isDirEndUser: boolean = false;
  isLoggedIn: Boolean = false;
  version: string = '';
  currentYear = new Date().getFullYear();
  menuVisible: boolean = false;
  partnerIsActive: boolean = true;
  isTestingPartner: boolean = false;
  partners: UserPartner[];

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private tools: ToolsService,
    private userRolesService: UserRolesService,
    public constants: RoleConstants
  ) {
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // detect browser reload
        this.authenticationService.restorePermissions();
      }
    });

    // this.authenticationService.isLoggedIn.subscribe((value) => {
    //   this.isLoggedIn = value;
    // });

    // this.router.events.subscribe((route) => {
    //   if (
    //     route instanceof NavigationEnd &&
    //     !(router.url.includes('license-value') || router.url.includes('subscription-value'))
    //   ) {
    //   }
    // });

    // this.tools.pageName.subscribe((value) => (this.pageName = value));
    // this.tools.menuVisible.subscribe((value) => (this.menuVisible = value));

    // this.authenticationService.selectedPartner.subscribe((data) => {
    //   if (data != null) {
    //     this.partnerName = data?.name;
    //     this.partnerIsActive = data?.isActive;

    //     this.isTestingPartner = [
    //       'Bedege AB',
    //       'K3 Business Solutions B.V.',
    //       'ArcherPoint, LLC',
    //       'RelateIT A/S',
    //       'Norsk Medisinaldepot AS',
    //       'LS Retail Internal Licenses - IS',
    //     ].includes(this.partnerName);
    //   } else {
    //     this.partnerName = '';
    //     this.partnerIsActive = true;
    //   }
    // });

    // this.tools.subNavigation.subscribe((elements) => (this.subBreadcrumbs = elements));
  }

  ngOnInit(): void {
    // this.version = environment.version;
    // this.authenticationService.selectedPartner.subscribe((data) => {
    //   this.isDirEndUser = data?.isDirEnd;
    // });
    // this.authenticationService.currentUserSubject.subscribe((data) => {
    //   if (data == null) return;
    //   this.userRolesService.SetUser(data);
    //   this.userName = data?.fullname;
    //   this.userRoles = data?.roles;
    //   this.isLsAdmin = data?.isLsAdmin;
    // });
    // this.authenticationService.partners.subscribe((data) => {
    //   if (data == null) return;
    //   this.partners = data;
    // });
  }

  // logoutClicked(event: Event): void {
  //   event.preventDefault();
  //   this.authenticationService.logout();
  // }

  // changeAccountClicked(event: Event): void {
  //   event.preventDefault();
  //   this.router.navigate(['select-partner']);
  // }

  // sidebarMenuVisible = false;

  // toggleNavbarCollapsing() {
  //   this.sidebarMenuVisible = !this.sidebarMenuVisible;
  // }

  // menuToggle: any = {
  //   BC: true,
  //   Admin: false,
  // };

  // togleMenuGroup(group: string) {
  //   this.menuToggle[group] = !this.menuToggle[group];
  // }
}
