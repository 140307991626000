export class RoleConstants {
  public Role = Role;
}

export enum Role {
  BCSUPER = 'BC SUPER',
  BCADMIN = 'BC ADMIN',
  BCACCOUNT = 'BC ACCOUNT',
  BCCUSTOME = 'BC CUSTOME',
  BCINVOICE = 'BC INVOICE',
  BCCERTIFI = 'BC CERTIFI',
  BCORDER = 'BC ORDER',
  PORTAL = 'PORTAL',
  BCSUPPORT = 'BC SUPPORT',
  AGREECONT = 'AGREE CONT',
  AGREEMARK = 'AGREE MARK',
  AGREESOFT = 'AGREE SOFT',
  AGREETECH = 'AGREE TECH',
  BREP = 'BREP',
  LSACADEMY = 'LS ACADEMY',
  FINANCIAL = 'FINANCIAL',
  CUSTOMERVISIBLE = 'CUSTOMERVISIBLE',
  EMAILINV = 'EMAIL INV',
  SALES = 'SALES',
}
