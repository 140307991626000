export const environment = {
  production: true,
  apiUrl: 'https://businesscenterapiprod.azurewebsites.net',
  thisUrl: 'https://businesshub.lsretail.com',
  authenticationServiceUrl: 'https://authentication.lsretail.com',
  version: '00.10',

  saltpay: {
    paymentApiUrl: 'https://securepay.borgun.is/securepay/default.aspx',
    secureKey: 'cd1ad16f4de74d60a34af761bff8c3cc',
    merchantid: '9109420',
    paymentgatewayid: '18300',
    merchantemail: 'accounting@lsretail.com',
  },
};
